<template>
  <v-form v-on:submit.prevent="loginApi">
    <v-container>
      <div v-for="notice in $store.state.notification">
        <div v-if="notice.msg=='会員登録が必要です'">
          <v-alert dismissible :type="notice.type" color="red">{{notice.msg}}</v-alert>
        </div>
        <div v-if="notice.msg=='別のIDをお試しください'">
          <v-alert dismissible :type="notice.type" color="red">{{notice.msg}}</v-alert>
        </div>
      </div>

      <v-card max-width="800px" elevation="10" class="py-5 mx-auto mt-5"
        ><v-row no-gutters justify="center">
          <h1>ユーザー登録</h1>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="6" justify="center">
            <!-- name="username" -->
            <v-text-field
            v-model="user.id" label="ID"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="6" justify="center">
              <!-- name="password" -->
            <v-text-field
            type="password"
              v-model="user.pw"
              label="パスワード"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters align="center" justify="center">
          <v-col cols="6" justify="center">
            <v-btn name="formSubmit" @click="loginApi()" x-large block :color="$store.state.settings.style.secondary" dark>Sign Up</v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center">
          <v-switch
            v-model="client_switch"
            label="クライアントユーザー登録"
          ></v-switch>
        </v-row>
        <v-row v-if="client_switch" no-gutters align="center" justify="center">
          <v-col cols="6" justify="center">
            <v-text-field
              v-model="user.cl"
              label="クライアントコード"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      client_switch:false,
    };
  },
  methods: {
    loginApi: function () {
      this.url = process.env.VUE_APP_API_URL + "/api/signup"
      if(this.$route.query.redirect && this.$route.query.hc){
        this.url += "?entrance="+this.$route.query.redirect
        this.url += "&hc="+this.$route.query.hc
      } else if(this.$route.query.redirect) {
        this.url += "?entrance="+this.$route.query.redirect
      } else if(this.$route.query.hc) {
        this.url += "?hc="+this.$route.query.hc
      }
      this.params = new URLSearchParams()
      // this.params.append("grant_type","password")
      // this.params.append("scope",[])
      this.now = new Date();
      this.Year = this.now.getFullYear();
      this.Month = this.now.getMonth()+1;
      this.date = this.now.getDate();
      this.Hour = this.now.getHours();
      this.Min = this.now.getMinutes();
      this.Sec = this.now.getSeconds();
      this.today = this.Year + "-" + this.Month + "-" + this.date + "_" + this.Hour + ":" + this.Min + ":" + this.Sec;
      this.params.append("username",this.user.id)
      this.params.append("password",this.user.pw)
      this.params.append("client_id",this.user.cl)
      this.params.append("client_secret",this.today)
      fetch(this.url, {
        method: "POST",
        body: this.params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res=> res.text()).then(data=>{
          data = JSON.parse(data);
          this.$store.dispatch("auth", data);
          if (this.$store.state.user.access_token){
            if (this.$route.query.redirect){
              this.$router.replace(this.$route.query.redirect);
            } else {
              this.$router.replace("/");
            }
          }
        });
    },
  },
  mounted() {
    // console.log(navigator.mediaDevices)
  },
};
</script>
