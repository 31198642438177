<template>
  <div class="notification">
    <Navigation title="Notification" />
      <v-container>
      <v-row no-gutters>
        <h1>お知らせ</h1>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="deleteNotice()"><v-icon>mdi-trash</v-icon>お知らせを空にする</v-btn>
      </v-row>
      <div v-for="notice in $store.state.notification">
        <v-alert dismissible :type="notice.type" :color="notice.color">{{notice.detail}}</v-alert>
      </div>

      </v-container>

  </div>
</template>

<script>
  import Vue from 'vue'
  import Navigation from '@/components/common/Navigation.vue'

  export default Vue.extend({
    components: { Navigation },
    name: 'Notification',
    data() {
      return {
        datas:[],
      }
    },
    methods: {
      fetchData:function(url){
        fetch(this.$store.state.api_url+url,{method:"GET", headers:{"Authorization":"Bearer "+localStorage.getItem("access_token")}}).then(res=>res.text()).then(data=>{
          this.$store.state.notification.push(JSON.parse(data))
        })
      },
      deleteNotice:function(){
        this.$store.state.notification = []
      }
    },
  })
</script>