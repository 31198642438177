<template>
    <v-navigation-drawer v-model="$store.state.drawer.right.state" right fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <br />
          <v-subheader>
                <v-row no-gutters>
          <!-- QR Comment -->
              <v-col justify="center" align="center">
                <v-btn icon x-large @click="$store.state.qr.read = !$store.state.qr.read"><v-icon>mdi-qrcode-scan</v-icon></v-btn>
                <br />
                <h4>読取</h4>
              </v-col>
              <v-col justify="center" align="center">
                <v-btn icon x-large @click="$store.state.qr.write = !$store.state.qr.write"><v-icon>mdi-qrcode</v-icon></v-btn>
                <br />
                <h4>表示</h4>
              </v-col>
              
              <v-col align="end">
                <v-btn icon @click="$store.state.drawer.right.state = !$store.state.drawer.right.state"><v-icon>mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-subheader>
          <br />
          <v-divider></v-divider>
          <br />
          <div v-for="app_list in $store.state.drawer.right.menu">
            <v-list-item :to="app_list.path"
              ><v-list-item-title
                ><v-btn icon :color="app_list.color"
                  ><v-icon>{{ app_list.icon }}</v-icon></v-btn
                >{{ app_list.title }}</v-list-item-title
              ></v-list-item
            >
          </div>
        </v-list-item-group>
      </v-list>
          <br />
          <v-divider></v-divider>
    </v-navigation-drawer>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "TopRight",
})
</script>