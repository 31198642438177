import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Store from '@/store/index'
import VueGtag from 'vue-gtag'

// QR Comment
import VueQrcodeReader from "vue-qrcode-reader";

import Signin from '@/views/service/auth/Signin.vue'
import Signup from '@/views/service/auth/Signup.vue'

import Home from '@/views/Home.vue'
import Settings from '@/views/service/public/settings/Settings.vue'
import Shop from '@/views/service/public/shop/Shop.vue'
import News from '@/views/service/public/news/News.vue'

import Notification from '@/views/service/general/notification/Notification.vue'
import Profile from '@/views/service/general/profile/Profile.vue'
import MyFootCard from '@/views/service/general/myfootcard/MyFootCard.vue'
import FootReport from '@/views/service/general/footreport/FootReport.vue'
import FootSearch from '@/views/service/general/footsearch/FootSearch.vue'
import FirstChild from '@/views/service/general/footsearch/FirstChild.vue' // Plan:Del
import FootHistory from '@/views/service/general/foothistory/FootHistory.vue'

import AutoInsole from '@/views/service/client/autoinsole/AutoInsole.vue'
import FootManager from '@/views/service/client/footmanager/FootManager.vue'
import DeviceManager from '@/views/service/client/devicemanager/DeviceManager.vue'
import GeneralManager from '@/views/service/client/generalmanager/GeneralManager.vue'

import DataBaseManager from '@/views/service/admin/databasemanager/DataBaseManager.vue'
import ClientManager from '@/views/service/admin/clientmanager/ClientManager.vue'


Vue.use(VueRouter)
// QR Comment
Vue.use(VueQrcodeReader)

const routes = [
  // Main
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title:"Home" }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: Signin,
    meta: { title:"SignIn" }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: Signup,
    meta: { title:"SignUp" }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { title:"Settings" }
  },
  // Public App
  {
    path: '/footsearch',
    name: 'FootSearch',
    component: FootSearch,
    meta: { title:"FootSearch" }
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    meta: { title:"Shop" }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: { title:"News" }
  },
  {
    path: '/first/:unique_code',
    name: 'FirstChild',
    component: FirstChild,
    meta: { title:"FirstChild" }
  },
  {
    path: '/footreport',
    name: 'FootReport',
    component: FootReport,
    meta: { title:"FootReport" }
  },
  // General And Up
  {
    path: '/foothistory',
    name: 'FootHistory',
    component: FootHistory,
    meta: { title:"FootHistory", requiresGeneral: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { title:"Profile", requiresGeneral: true }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification,
    meta: { title:"Notification", requiresGeneral: true }
  },
  {
    path: '/myfootcard',
    name: 'MyFootCard',
    component: MyFootCard,
    meta: { title:"MyFootCard", requiresGeneral: true }
  },
  {
    path: '/autoinsole',
    name: 'AutoInsole',
    component: AutoInsole,
    meta: { title:"AutoInsole", requiresClient: true }
  },
  {
    path: '/devicemanager',
    name: 'DeviceManager',
    component: DeviceManager,
    meta: { title:"DeviceManager", requiresClient: true }
  },
  {
    path: '/generalmanager',
    name: 'GeneralManager',
    component: GeneralManager,
    meta: { title:"GeneralManager", requiresClient: true }
  },
  {
    path: '/clientmanager',
    name: 'ClientManager',
    component: ClientManager,
    meta: { title:"ClientManager", requiresAdmin: true }
  },
  {
    path: '/databasemanager',
    name: 'DataBaseManager',
    component: DataBaseManager,
    meta: { title:"DataBaseManager", requiresAdmin: true }
  },
  {
    path: "*",
    redirect: "/",
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  switch (Store.state.user.class){
    case "general":
        if (to.matched.some(record => record.meta.requiresAdmin)){
          Store.state.notification.unshift({type:"error", color:"error", detail:"Admin Appの登録が必要です"})
        } else if (to.matched.some(record => record.meta.requiresClient)){
          Store.state.notification.unshift({type:"warning", color:"warning", detail:"Client Appの登録が必要です"})
        } else {
          next()
        }
        break;
      case "client":
        if (to.matched.some(record => record.meta.requiresAdmin)){
          Store.state.notification.unshift({type:"error", color:"error", detail:"Admin Appへの登録が必要です"})
        } else {
          next()
        }
        break;
      case "admin":
        next()
        break;
      default:
        if (to.matched.some(record => record.name == "Profile")){
          next({ path: '/signin',query: { redirect: to.path }});
        }
        if (to.matched.some(record => record.meta.requiresAdmin)){
          Store.state.notification.unshift({type:"error", color:"error", detail:"A登録が必要です"})
        } else if (to.matched.some(record => record.meta.requiresClient)){
          Store.state.notification.unshift({type:"warning", color:"warning", detail:"C登録が必要です"})
        } else if (to.matched.some(record => record.meta.requiresGeneral)){
          Store.state.notification.unshift({type:"info", color:"info", detail:"会員登録が必要です"})
          // next({ path: '/signin',query: { redirect: to.path }});
        }else {
          next()
        }
  }
});


export default router
