<template>
  <div class="autoinsole">
    <Navigation title="AutoInsole" />
    <v-container>
        <h1>Auto Insole System</h1>
        <br>

      <v-data-table :items="datas" :headers="headers">
        <!-- <template v-slot:item="{ item }">
          {{item}}
        </template> -->
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "AutoInsole",
  data() {
      return {
          datas:[],
          headers:[
            {text:"注文日", value:"order_date"},
            {text:"氏名", value:"name"},
            {text:"担当者", value:"manager_name"},
            {text:"売上額", value:"total_price"},
            {text:"納品日", value:"order_date"},
          ],
      }
  },
  methods: {
    fetchData: function (url) {
      fetch(this.$store.state.api_url + url, {method:"GET",headers: { Authorization: "Bearer " + localStorage.getItem("access_token")}}).then((res) => res.text()).then((data) => {
          data = JSON.parse(data)
          for (let i=0;data.length>i;i++){
            this.datas.push(data[i])
          }
        });
    },
  },
  mounted() {
    this.fetchData("/api/autoinsole")
  },
});
</script>
