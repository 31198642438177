<template>
  <div class="profile">
    <Navigation title="Profile" />
      <v-container>
      <h1>Profile page</h1>
        <v-btn to="/about">サンプルページへ</v-btn>
      </v-container>

  </div>
</template>

<script>
  import Vue from 'vue'
  import Navigation from '@/components/common/Navigation.vue'

  export default Vue.extend({
    components: { Navigation },
    name: 'Profile',
    data() {
      return {
        datas:[],
        // headers: [
        //   { text: "Fetch All", value: "fetch_all" },
        //   { text: "Fetch Status", value: "fetch_status" },
        //   { text: "Fetch Value", value: "fetch_val" },
        // ],
      }
    },
    methods: {
      // fetchProfile:function(url){
      //   fetch(this.$store.state.api_url+url,{method:"GET", headers:{"Authorization":"Bearer "+localStorage.getItem("access_token")}}).then(res=>res.text()).then(data=>{
      //     this.datas.push(JSON.parse(data))
      //   })
      // }
    },
  })
</script>