<template>
    <div class="footsearch">
      <Navigation title="FootSearch"/>

      <v-container>
        <v-row no-gutters>
            <v-col cols="12" align="center">
              <v-alert border="bottom" dismissible>ユニークコードから登録先を検出しましょう</v-alert>
            </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
        
        <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field label="ユニークコードで検索" v-model="unique_code" placeholder="02HGCLLOY3075WRDBDW0"></v-text-field>
              </v-col>
              <v-row no-gutters justify="space-between">
              <v-btn to="/">戻る</v-btn>
                <v-btn @click="searchData(unique_code)">検索</v-btn>
              </v-row>
              </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "FootSearch",
  data() {
    return {
      unique_code:"",
      data:null,
    };
  },
  methods: {
    searchData:function(unique_code){
      // console.log(this.$route.path+"/"+unique_code)
      console.log(this.data)
      console.log(this.unique_code)
    },
  },
  mounted() {
    if(this.$route.params.unique_code){
      this.unique_code = this.$route.params.unique_code
    }
  },
  watch: {
    unique_code:function(from,to){
    }
  },
});
</script>