<template>
  <div class="Settings">
    <Navigation title="Settings" />
    <v-container>
      <v-row no-gutters class="my-2">
        <h1 class="hidden-xs-only">基本設定</h1>
        <h3 class="hidden-sm-and-up">基本設定</h3>
        <v-spacer></v-spacer>
        <v-btn @click="defaultSet()" class="mr-2" :color="$store.state.settings.style.negative" dark>デフォルトに戻す</v-btn>
        <v-btn @click="settingsUpdate()" :color="$store.state.settings.style.positive" dark>変更を確定</v-btn>
      </v-row>
        
        <v-row no-gutters justify="start" align="center" align-content="space-around">
          <v-col cols="12">
            <v-row no-gutters>
            <v-radio-group class="ma-3" :name="select_theme" v-model="select_theme" column label="テーマカラー">
            <v-radio label="メイン" :color="$store.state.settings.style.primary" value="primary"></v-radio>
            <v-radio label="サブ" :color="$store.state.settings.style.secondary" value="secondary"></v-radio>
            <v-radio label="お知らせ" :color="$store.state.settings.style.notice" value="notice"></v-radio>
            <v-radio label="ポジティブ" :color="$store.state.settings.style.positive" value="positive"></v-radio>
            <v-radio label="ネガティブ" :color="$store.state.settings.style.negative" value="negative"></v-radio>
            </v-radio-group>
          <v-color-picker
            name="color"
            v-model="$store.state.settings.style[select_theme] = $store.state.settings.style[select_theme]"
            label="テーマカラー"
            hide-inputs
          ></v-color-picker>
            </v-row>
          </v-col>
        </v-row>

        <v-row no-gutters justify="start">
          <v-switch
            name="dark"
            :color="$store.state.settings.style.primary"
            v-model="$store.state.settings.style.dark"
            label="ダークモード"
          ></v-switch>
        </v-row>
        <v-row no-gutters justify="start">
          <v-switch
            name="push"
            :color="$store.state.settings.style.secondary"
            v-model="$store.state.settings.push"
            label="プッシュ通知"
          ></v-switch>
        </v-row>
        <v-row no-gutters justify="start">
          <v-switch
            name="camera"
            :color="$store.state.settings.style.notice"
            v-model="$store.state.settings.camera"
            label="カメラ"
          ></v-switch>
        </v-row>
        <!-- Footer Margin -->
        <v-row no-gutters>
          <div style="height:100px;"></div>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "Settings",
  data() {
      return {
          select_theme:"primary",
          theme_color:"",
      }
  },
  methods: {
    settingsUpdate:async function(){
      // console.log(this.$store.state.settings)
      fetch(process.env.VUE_APP_API_URL+"/settings",{
        method:"POST",
        headers:{"Authorization":"Bearer "+localStorage.getItem("access_token"),"Content-Type":"application/json"},
        body:JSON.stringify(this.$store.state.settings)
        }).then(res=>res.text()).then(data=>this.$store.state.notification.unshift(JSON.parse(data)))
    },
    defaultSet:function(){
      this.$store.state.settings = {
        push:false,
        camera:false,
        style:{
          primary:this.$store.state.default_main_color,
          secondary:this.$store.state.default_sub_color,
          notice:this.$store.state.default_notice_color,
          positive:this.$store.state.default_positive_color,
          negative:this.$store.state.default_negative_color,
          dark:false,
        },
      }
    },
    checkCamera:async function(camera){
      // console.log(camera)
      // stream = await navigator.mediaDevices.getUserMedia({
        // audio: true,
      // video: true
      // })
    }
  },
  mounted() {
    // this.$store.state.settings.menu.top_right = []
    // console.log(this.$store.state.settings.camera)
    },
  watch: { 
    '$store.state.settings.style.dark'(from,to){
        this.$vuetify.theme.dark = this.$store.state.settings.style.dark
      // console.log(this.$store.state.settings.style.text)
      },
    '$store.state.settings.camera' (camera) {
      // console.log(camera)
    this.checkCamera(camera)
    }
  }

});
</script>
