<template>
  <v-form v-on:submit.prevent="loginApi">
    <v-container>
      <v-card max-width="800px" elevation="10" class="py-5 mx-auto mt-5"
        ><v-row no-gutters justify="center">
          <h1>ユーザーログイン</h1>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="6" justify="center">
            <v-text-field
            v-model="user.id" label="ID"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="6" justify="center">
            <v-text-field
            type="password"
              v-model="user.pw"
              label="パスワード"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="6" justify="center">
            <v-btn name="formSubmit" @click="loginApi()" x-large block :color="$store.state.settings.style.primary" dark>Sign In</v-btn
            >
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" justify="center">
                <h3 class="hidden-xs-only text-center">アカウントをお持ちでない方はこちら</h3>
                <h5 class="hidden-sm-and-up text-center">アカウントをお持ちでない方はこちら</h5>
              </v-col>
            </v-row>
            <br>
            <v-row no-gutters align="center" justify="center">
              <v-col cols="6" justify="center">
                <div v-if="$route.query.redirect">
                  <v-btn :to="'/signup?redirect='+$route.query.redirect" x-large block :color="$store.state.settings.style.primary" dark>Sign Up</v-btn>
                </div>
                <div v-else>
                <v-btn to="signup" x-large block :color="$store.state.settings.style.secondary" dark>Sign Up</v-btn>
                </div>
              </v-col>
            </v-row>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  methods: {
    loginApi: function () {
      this.url = process.env.VUE_APP_API_URL + "/api/signin"
      this.params = new URLSearchParams()
      this.params.append("grant_type","password")
      this.params.append("scope",[])
      this.params.append("username",this.user.id)
      this.params.append("password",this.user.pw)
      this.params.append("client_id",this.user.id+"-client")
      this.params.append("client_secret",this.user.pw+"-secret")

      fetch(this.url, {
        method: "POST",
        body: this.params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res=> res.text()).then(data=>{
          data = JSON.parse(data);
          this.$store.dispatch("auth", data);
          if (this.$store.state.user.access_token){
            if (this.$route.query.redirect){
              this.$router.replace(this.$route.query.redirect);
            } else {
              this.$router.replace("/");
            }
          }
        });
    },
  },
};
</script>
