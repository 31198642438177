<template>
        <v-navigation-drawer v-model="$store.state.drawer.left.state" fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <br />
          <v-subheader>
            <v-row no-gutters>
              <v-btn icon @click="$store.state.drawer.left.state = !$store.state.drawer.left.state"><v-icon>mdi-close</v-icon></v-btn>
              <v-spacer></v-spacer>
              <div v-if="$store.state.user.class=='general'"><v-col>一般</v-col></div>
              <div v-if="$store.state.user.class=='client'"><v-col>企業</v-col></div>
              <div v-if="$store.state.user.class=='admin'"><v-col>管理者</v-col></div>
              <v-btn class="mx-auto" text to="/profile"><h3>{{ $store.state.user.username }} 様</h3></v-btn>
            </v-row>
          </v-subheader>
          <br />
          <v-divider></v-divider>
          <br />
          <div v-for="app_list in $store.state.drawer.left.menu">
            <v-list-item :to="app_list.path">
              <v-list-item-title>
                <v-btn icon :color="app_list.color"><v-icon>{{ app_list.icon }}</v-icon></v-btn>
                {{ app_list.title }}
                </v-list-item-title>
            </v-list-item>
          </div>
          <br />
          <v-divider></v-divider>
          <br />

          <div v-for="app_list in $store.state.drawer.absolute">
            <v-list-item :to="app_list.path">
              <v-list-item-title>
                <v-btn icon :color="app_list.color"><v-icon>{{ app_list.icon }}</v-icon></v-btn>
                  {{ app_list.title }}
                </v-list-item-title>
            </v-list-item>
          </div>
            <v-list-item v-if="$store.state.user.username =='GuestUser'" to="/signin">
              <v-list-item-title>
                <v-btn icon :color="$store.state.settings.style.positive"><v-icon>mdi-login</v-icon></v-btn>
                ログイン
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="$store.state.user.username !='GuestUser'" onclick="window.location.href='/'">
              <v-list-item-title>
                <v-btn icon :color="$store.state.settings.style.negative"><v-icon>mdi-logout</v-icon></v-btn>
                ログアウト
              </v-list-item-title>
            </v-list-item>
          <br />
          <v-divider></v-divider>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "LeftMenu",
})

</script>