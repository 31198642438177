<template>
  <div class="GeneralManager">
    <Navigation title="GeneralManager" />
    <v-container>
        <h1>General Manager</h1>
        <br>
        <!-- <v-btn @click="fetchData('/api/devicemanager')">データ取得</v-btn> -->
        
<!-- {{datas}} -->
      <v-data-table :items="datas" :headers="headers">
        <!-- <template v-slot:item="{ item }">
          {{item.detail}}
        </template> -->
        <!-- <template v-slot:item.fetch_status="{ item }">
          {{item}}
        </template>
        <template v-slot:item.fetch_val="{ item }">
            <div v-if="item.detail">{{item.detail}}</div>
            <div v-else>{{item.test}}</div>
        </template>-->
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "GeneralManager",
  data() {
    return {
        datas:[],
        headers:[
          { text: "企業名", value: "client" },
          { text: "開始日", value: "start_date" },
          { text: "取引内容", value: "discussion" },
        ]
      }
  },
  methods: {
    fetchData: function (url) {
      fetch(this.$store.state.api_url + url, {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
      })
        .then((res) => res.text())
        .then((data) => {
          this.datas = JSON.parse(data);
          // console.log(this.app_list);
        });
    },
  },
  mounted() {
    this.fetchData("/api/GeneralManager")
  },
});
</script>
