<template>
    <div class="FirstChild">
      <v-btn to="/first">戻る</v-btn>
        <v-alert>計測結果</v-alert>
        <v-container v-if="this.$route.query.client=='MKH'">
          <h3>Client = MKH <br> Measure Data</h3>
              <v-row no-gutters justify="center">
            <v-row no-gutters justify="center">
              <v-col cols="6" sm="4">
                <img :src="$store.state.api_url+'/file/jpg/front/'+unique_code" width="100%">
              </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="6" sm="2">
                <img :src="$store.state.api_url+'/file/jpg/left/'+unique_code" width="100%">
                </v-col>
                <v-col cols="6" sm="2">
                <img :src="$store.state.api_url+'/file/jpg/right/'+unique_code" width="100%">
                </v-col>
              </v-row>
            </v-row>
        </v-container>
        
              <!-- <v-col><v-btn @click="getData(unique_code)">GetBank</v-btn></v-col> -->

        <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field label="ユニークコードで検索" v-model="unique_code" placeholder="02HGCLLOY3075WRDBDW0"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn @click="searchData(unique_code)">検索</v-btn>
              </v-col>
              </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "FirstChild",
  data() {
    return {
      unique_code:"",
      // unique_code:"02HGCLLOY3075WRDBDW0",
      data:null,
      // app_list: [],
      // headers: [
      // ],
    };
  },
  methods: {
    searchData:function(val){
      // console.log(this.$route)
      // this.$route.path = this.$route.path+"/"+val
      // console.log(this.$route.path+"/"+val)
      this.$router.replace("/first/"+val)
    },
    getData:function(val){
    //   console.log(val)
      // val = ""
      // fetch(this.$store.state.api_url+"/first/"+val).then(res=>res.text()).then(data=>this.data = data)
    }
  },
  mounted() {
    if(this.$route.params.unique_code){
      this.unique_code = this.$route.params.unique_code
    }
    // this.$store.state.qr.value ="rssss"
    // this.getData("02HGCLLPBT0760ZSUNN8")
    //   console.log(this.$route.path)
      // if (this.$route.fullPath.split("unique_code")[1]){
      //     console.log(this.$route.fullPath)
      // }
  },
  watch: {
    unique_code:function(from,to){
    //   console.log(to)
    //   console.log(from)
      // location.replace(this.$route.path+to)
    }
  },
});
</script>