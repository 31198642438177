<template>
  <div class="databasemanager">
    <Navigation title="データ 管理" />
    
    <v-container>
      <v-card class="">

        <v-card-title>
          <v-combobox
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            :items="search_items"
            hide-details
            single-line
          ></v-combobox>
        </v-card-title>

        <v-data-table :items="datas" :headers="headers">
        </v-data-table>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "DeviceManager",
  data() {
    return {
        search:"",
        search_items:["test","2",3],
        datas:[],
        headers:[
          { text: "導入日", value: "date" },
          { text: "導入先", value: "client" },
          { text: "計測器-ID(FBM-ID)", value: "machine" },
        ]
      }
  },
  methods: {
    fetchData: function (url) {
      fetch(this.$store.state.api_url + url, {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
      })
        .then(res => res.text())
        .then(data => {
          data = JSON.parse(data)
          if (data.detail != 'Not Found'){
            this.datas = data;
          }
        });
    },
  },
  mounted() {
    // this.fetchData("/api/devicemanager")
  },
});
</script>
