<template>
  <div class="home">
    <Navigation title="ホーム" />
    <v-container>

      <div v-if="$store.state.user.username == 'GuestUser'">
        <h3>ようこそ {{ $store.state.user.username }} 様</h3>

      </div>
      <div v-else>
        <h1>おかえりなさい {{ $store.state.user.username }} 様</h1>
        
        <v-btn @click="fetchData('/api/users/me/')">me</v-btn>
        <v-btn @click="fetchData('/api/users/me/items/')">items</v-btn>
        <v-btn @click="fetchData('/api/status/')">status</v-btn>
        <v-btn @click="fetchData('/api/current/')">current</v-btn>
        <v-btn @click="fetchData('/mypage')">mypage</v-btn>
        <v-btn @click="fetchData('/api/mypage/')">mypage/</v-btn>
        <br>
        <div v-for="d in data">
          <v-alert dismissible :type="d.type" :color="d.color">{{d.detail}}</v-alert>
        </div>
      </div>
    </v-container>

  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "Home",
  data() {
    return {
      data: [],
    };
  },
  methods: {
    fetchData: function (url) {
      fetch(process.env.VUE_APP_API_URL + url, {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
      })
        .then(res => res.text())
        .then(data => {
          this.data.push(JSON.parse(data));
        });
    },
  },
});
</script>
