<template>
    <div class="qrdialog">
    <!-- QR Dialog -->
    <v-dialog v-model="$store.state.qr.write" :overlay-color="$store.state.settings.style.primary" max-width="500px">
      <div class="hidden-xs-only">
        <VueQrcode :val="$store.state.qr.value"/>
      </div>
      <div class="hidden-sm-and-up">
        <v-row no-gutters justify="center">
          <v-col cols="auto" align="center">
            <VueQrcode :val="$store.state.qr.value"/>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import VueQrcode from "@/components/common/qrcode/Qrcode.vue";

export default Vue.extend({
  name: "QrDialog",
  components: {VueQrcode},
  data() {
    return {
      datas:[],
    }
  },
})

</script>