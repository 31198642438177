<template>
  <div class="myfootcard">
    <Navigation title="MyFootCard" />
    <v-container>
        <h1>MyFootCard</h1>
        <br>
        <v-btn @click="fetchProfile('/api/public/')">データ取得</v-btn>
        <br>
        <v-btn @click="fetchProfile('/api/private/')">詳細データ取得</v-btn>


      <v-data-table :items="datas" :headers="headers">
        <template v-slot:item.detail="{ item }">
          {{item}}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "MyFootCard",
  data() {
    return {
        datas:[],
        headers:[
            { text: "取得内容", value: "detail" },
        ]
      }
  },
  methods: {
    fetchProfile: function (url) {
      fetch(this.$store.state.api_url + url, {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
      })
        .then((res) => res.text())
        .then((data) => {
          this.datas.push(JSON.parse(data));
          // console.log(this.app_list);
        });
    },
  },
});
</script>
