<template>
  <v-app v-cloak>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  methods : {
    setMeta(route){
      if(route.meta.title){
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      if(route.meta.desc){
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
    },
    checkSync(){
      // console.log("checkSync")
      if(this.$store.state.update){
        window.navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          for(let registration of registrations) {
            registration.unregister();
          }
        });
        window.location.reload(true);
      }
    }
  },
  mounted(){
    let route = this.$route;
    this.setMeta(route);
    this.checkSync()
  },
  watch: { 
    '$route' (route, from) {
      this.setMeta(route);
      // this.checkSync();
    }
  }
});
</script>

<style>
.v-btn{
  text-transform: none;
}
</style>