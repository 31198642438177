<template>
  <div class="shop">
    <Navigation title="Shop" />
    <v-container>
        <h1>Shop Page</h1>
        <!-- {{datas}} -->
        <div v-for="(data,i) in datas" :key="i">
            No . {{i}}<br>
            タイトル : {{data.title}}<br>
            内容 : {{data}}
            価格 : {{data.body}}
            
            <!-- {{key}} -->
        </div>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "Shop",
  data() {
      return {
          datas:[],
      }
  },
  methods: {
      getData:function(){
          let url = this.$store.state.api_url + "/api/shop"
          fetch(url,{method:"GET"}).then(res=>res.text()).then(data=>this.datas =JSON.parse(data))
      }
  },
  mounted() {
      this.getData()
  },
});
</script>
