import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app_title:process.env.VUE_APP_TITLE,
    default_main_color: process.env.VUE_APP_MAIN_COLOR,
    default_sub_color: process.env.VUE_APP_SUB_COLOR,
    default_notice_color: process.env.VUE_APP_NOTICE_COLOR,
    default_positive_color: process.env.VUE_APP_POSITIVE_COLOR,
    default_negative_color: process.env.VUE_APP_NEGATIVE_COLOR,
    update:false,
    notification:[],
    user: {
      username:"GuestUser",
      class:"None",
      scopes:[],
      access_token:"",
      refresh_token:"",
    },
    settings:{
      push:false,
      camera:false,
      style:{
        dark:false,
        primary:process.env.VUE_APP_MAIN_COLOR,
        secondary:process.env.VUE_APP_SUB_COLOR,
        notice:process.env.VUE_APP_NOTICE_COLOR,
        positive:process.env.VUE_APP_POSITIVE_COLOR,
        negative:process.env.VUE_APP_NEGATIVE_COLOR,
      },
    },
    general_list:[
      {title:"FootSearch",icon:"mdi-cloud-search-outline",path:"/footsearch", color:process.env.VUE_APP_MAIN_COLOR},
      {title:"FootHistory",icon:"mdi-human-male-female-child",path:"/foothistory", color:"#EA0606"},
      {title:"フットレポート",icon:"mdi-file-chart-outline",path:"/footreport", color:process.env.VUE_APP_NOTICE_COLOR},
      {title:"フットNews",icon:"mdi-newspaper",path:"/news", color:process.env.VUE_APP_POSITIVE_COLOR},
      {title:"Shop",icon:"mdi-store",path:"/shop", color:"green"},
      {title:"マイページ",icon:"mdi-human-male-female-child",path:"/foothistory", color:"#EA0606"},
    ],
    client_list:[
      {title:"お知らせ",icon:"mdi-bell",path:"/notification", color:process.env.VUE_APP_NOTICE_COLOR},
      {title:"計測データ 管理",icon:"mdi-clipboard-text-search-outline",path:"/footmanager", color:process.env.VUE_APP_POSITIVE_COLOR},
      {title:"会員 管理",icon:"mdi-account-box-multiple-outline",path:"/generalmanager", color:"green"},
      {title:"計測機 管理",icon:"mdi-store-clock",path:"/devicemanager",color:process.env.VUE_APP_NEGATIVE_COLOR},
      {title:"AutoInsole",icon:"mdi-foot-print",path:"/autoinsole", color:"#E91E63"},
      // {title:"CheckMarketPrice : チェック",icon:"mdi-bitcoin",path:"/checkmarketprice", color:"notice"},
    ],

    // QR Comment
    qr:{read:false,write:false,value:""},
    drawer:{
      absolute:[
        {title:"ClientManager",icon:"mdi-account-key-outline",path:"/clientmanager", color:process.env.VUE_APP_POSITIVE_COLOR},
        {title:"DataBaseManager",icon:"mdi-database-lock",path:"/databasemanager", color:process.env.VUE_APP_MAIN_COLOR},
        {title:"設定",icon:"mdi-cog-outline",path:"/settings", color:process.env.VUE_APP_NOTICE_COLOR},
        {title:"アプリ更新",icon:"mdi-cog-sync-outline",path:"/update", color:process.env.VUE_APP_SUB_COLOR},
      ],
      left:{state:false,menu:[]},
      right:{state:false,menu:[]},
    }
  },
  mutations: {
    async loginUser(state, user) {
      if (user.username){
        state.user.username = user.username;
        state.user.class = user.class;
        state.user.scopes = user.scopes
        state.user.access_token = user.access_token;
        state.user.refresh_token = user.refresh_token;
        state.settings = user.settings;
        localStorage.setItem('access_token', user.access_token);
      } else {
        state.notification.unshift(user)
        router.push("/signin");
      }
    },
  },
  actions: {
    auth(context, user) {
      context.commit('loginUser', user);
  }
  },
  modules: {
  }
})
