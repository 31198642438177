<template>
  <div>
    <div class="hidden-xs-only">
      <vue-qrcode
        :value="$store.state.qr.value"
        :options="option"
        tag="img"
      ></vue-qrcode>
      <br>
      {{$store.state.qr.value}}
    </div>
    <div class="hidden-sm-and-up">
      <vue-qrcode
        :value="$store.state.qr.value"
        :options="xs_option"
        tag="img"
      ></vue-qrcode>
      <br>
      {{$store.state.qr.value}}
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      option: {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        width: 500,
        margin: 1,
        color: {
        dark: this.$store.state.settings.style.primary,
        light: "#FFFFFF",
        },
      },
      xs_option: {
        errorCorrectionLevel: "M",
        maskPattern: 0,
        width: 250,
        margin: 1,
        color: {
          dark: this.$store.state.settings.style.primary,
          light: "#FFFFFF",
        },
      },
    };
  },
};
</script>
<style scoped></style>
