<template>
  <div class="footreport">
    <Navigation title="FootReport" />
    <v-container>
      <h1>FootReport</h1>

      <!-- <div v-if="$store.state.user.username == 'GuestUser'">
        <h2>ようこそ {{ $store.state.user.username }} 様</h2>
        <h1>アンケートから会員登録で 1,000 FootCoin 獲得</h1>
      </div>
      <div v-else>
        <h2>おかえりなさい {{ $store.state.user.username }} 様</h2>
        <h4>未獲得の FootCoin があります</h4>
      </div> -->

      <h2>{{ $store.state.user.username }} 様</h2>
      <h4>足の状態を記録して FootCoin Get</h4>
      

        <v-row no-gutters justify="end" v-if="$store.state.user.class=='client'||$store.state.user.class=='admin'">
        <v-btn @click="add_dialog=!add_dialog">アンケートを追加</v-btn>
        </v-row>

      <div v-for="data in datas">
        <v-card :href="data.url" class="ma-3" :color="data.color">
          <v-container>
            <v-card-title>
              <h3>{{data.title}}</h3>
            </v-card-title>
            <v-card-description>
              <p>{{data.title}}の説明</p>
            </v-card-description>
          </v-container>
        </v-card>
      </div>
    </v-container>

    <v-dialog v-model="add_dialog" fullscreen fixed>
      <v-card>
        <v-container>

      <v-btn @click="add_dialog=!add_dialog" class="ml-2 mt-2">戻る</v-btn>
      <v-text-field v-model="custom_label" label="質問事項"></v-text-field>
      <v-select v-model='select_form' :items="select_list"></v-select>

      <v-col cols="6">
        <div class="text-center ma-1">プレビュー</div>
        <div v-html="body"></div>
      </v-col>

      <!-- <div class="hidden-xs-only">
          <v-row no-gutters justify="center">
              <v-col cols="6" align="center">
                <p class="text-center"></p>
                <v-row no-gutters justify="center">
                  <v-col cols="10" justify="center">
                <v-textarea v-model="body" label="htmlで記事を投稿(<v-...>が一部利用できます</v-...>)" autofocus filled auto-grow></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <div class="text-center ma-1">プレビュー</div>
                <div v-html="body"></div>
              </v-col>
        </v-row>
      </div>
      <div class="hidden-sm-and-up">
        <v-row no-gutters justify="center">
          <v-col cols="auto" align="center">
            <v-row no-gutters>
              <v-col cols="12" align="center">
                <p class="text-center">htmlで記事を投稿</p>
                <v-row no-gutters>
                <v-textarea v-model="body"></v-textarea>
                </v-row>
              </v-col>
              <v-col cols="12" align="center">
                <div class="text-center ma-1">プレビュー</div>
                <div v-html="body"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div> -->
{{custom_form}}
      <v-row no-gutters justify="end" class="mr-2 mb-2">
        <v-btn @click="addForm(custom_label,select_form)">追加</v-btn>
          <v-btn @click="postData(body)">登録</v-btn>
      </v-row>
        </v-container>
    </v-card>

    </v-dialog>

  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "FootMedical",
  data() {
      return {
          datas:[],
          add_dialog:false,
          body:"",
          custom_label:"",
          custom_form:[],
          select_form:"",
          // select_list: ["","v-text-field","v-textarea","v-select","v-radio","v-combobox"],
          select_list: ["","テキスト:v-text-field","複数行テキスト:v-text-area","セレクト:v-select","ラジオ:v-radio","コンボ:v-combobox"],
          param_list: [],
      }
  },
  methods: {
    addForm: function(label,parts){
      console.log(label)
      console.log(parts)
      this.custom_form.push({
        label: label,
        parts: parts.split(":")[1],
        params:this.param_list,
        })
        // this.body+="<"+parts+
      // this.custom_form[String(label)] = String(parts)
    },
    fetchData: function (url) {
      fetch(this.$store.state.api_url + url, {
        method: "GET",
        // headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
      })
        .then((res) => res.text())
        .then((data) => {
          console.log(JSON.parse(data))
          this.$store.state.notification.push(JSON.parse(data));
          // console.log(this.app_list);
        });
    },
  },
  mounted() {
    // this.fetchData("/api/footmedical")
  },
  watch: {
    select_form: function(select_from,select_to){
      // console.log("select_from")
      // console.log(select_from)
      // console.log(select_from)
    }
  },
});
</script>
