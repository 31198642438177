<template>
  <div class="news">
    <Navigation :title="$route.meta.bar_title" />
    <v-container>
        <h1>News Page</h1>
        <!-- <div v-if="$store.state.user.class=='client'||$store.state.user.class=='admin'"> -->
          <v-row no-gutters justify="end">
          <v-btn :color="$route.meta.color" @click="add_dialog=!add_dialog">記事を追加</v-btn>
          </v-row>
          <!-- </div> -->
        <div v-for="(data,i) in datas" :key="i">
            <v-card class="ma-2" :href="'/api/news/'+data.id" hover>
            No . {{i}}<br>
            タイトル : {{data.title}}<br>
            内容 : {{data.body}}
            </v-card>
        </div>
    </v-container>
        <!-- Add Dialog -->
    <v-dialog v-model="add_dialog" fullscreen fixed>
      <v-card>

      <v-btn @click="add_dialog=!add_dialog" class="ml-2 mt-2">戻る</v-btn>
      <div class="hidden-xs-only">
          <v-row no-gutters justify="center">
              <v-col cols="6" align="center">
                <p class="text-center"></p>
                <v-row no-gutters justify="center">
                  <v-col cols="10" justify="center">
                <v-textarea v-model="body" label="htmlで記事を投稿(<v-...>が一部利用できます</v-...>)" autofocus filled auto-grow></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <div class="text-center ma-1">プレビュー</div>
                <div v-html="body"></div>
              </v-col>
        </v-row>
      </div>
      <div class="hidden-sm-and-up">
        <v-row no-gutters justify="center">
          <v-col cols="auto" align="center">
            <v-row no-gutters>
              <v-col cols="12" align="center">
                <p class="text-center">htmlで記事を投稿</p>
                <v-row no-gutters>
                <v-textarea v-model="body"></v-textarea>
                </v-row>
              </v-col>
              <v-col cols="12" align="center">
                <div class="text-center ma-1">プレビュー</div>
                <div v-html="body"></div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-row no-gutters justify="end" class="mr-2 mb-2">
          <v-btn @click="postData(body)">登録</v-btn>
      </v-row>
    </v-card>

    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Navigation from "@/components/common/Navigation.vue";

export default Vue.extend({
  components: { Navigation },
  name: "News",
  data() {
      return {
          datas:[],
          res:[],
          body:"",
          add_dialog:false,
      }
  },
  methods: {
      getData:function(){
          let url = this.$store.state.api_url + "/api/news"
          fetch(url,{method:"GET"}).then(res=>res.text()).then(data=>this.datas =JSON.parse(data))
      },
      postData:function(val){
          let url = this.$store.state.api_url + "/api/news"
          fetch(url,{method:"POST",headers:{"Authorization":"Bearer "+localStorage.getItem("access_token")}}).then(res=>res.text()).then(data=>this.$store.state.notification.unshift(JSON.parse(data)))
      }
  },
  mounted() {
      this.getData()
  },
});
</script>
