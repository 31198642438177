<template>
  <div>
    <v-app-bar app :color="$store.state.settings.style.primary" dark>
      <v-row no-gutters align="center" justify="space-between">
        <v-btn icon @click="$store.state.drawer.left.state = !$store.state.drawer.left.state"><v-icon>mdi-menu</v-icon></v-btn>

        <v-btn to="/" class="hidden-xs-only" text><h2>{{ $store.state.app_title }}</h2></v-btn>
        <v-btn to="/" class="hidden-sm-and-up" icon x-small><v-icon>mdi-home</v-icon></v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-title center>
          <div>
            <h3 class="hidden-xs-only">{{ title }}</h3>
            <h5 class="hidden-sm-and-up">{{ title }}</h5>
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div v-if="$store.state.user.username!='GuestUser'">
        <v-btn text to="/profile" class="hidden-xs-only"><h3>{{ $store.state.user.username }} 様</h3></v-btn>
        </div>
        <div v-else>
        <v-btn text to="/signin" class="hidden-xs-only"><h3>ログイン<v-icon>mdi-login</v-icon></h3></v-btn>
        </div>

        <div  v-if="$store.state.notification.length!=0">
          <v-btn icon to="/notification"><v-icon>mdi-bell</v-icon></v-btn>
          <v-badge overlap :color="$store.state.settings.style.notice" :content="$store.state.notification.length"></v-badge>
        </div>
        <div v-else>
          <v-btn icon to="/profile" class="hidden-sm-and-up"><v-icon>mdi-account</v-icon></v-btn>
        </div>
        <v-btn icon @click="$store.state.qr.read = !$store.state.qr.read"><v-icon>mdi-qrcode-scan</v-icon></v-btn>
        <v-btn icon @click="$store.state.drawer.right.state = !$store.state.drawer.right.state"><v-icon>mdi-microsoft-xbox-controller-menu</v-icon></v-btn>
      </v-row>
    </v-app-bar>

    <!-- <TopLeft/> -->
    <!-- <TopRight/> -->
    <!-- <BottomLeft/> -->
    <!-- <BottomRight/> -->
    <LeftMenu/>
    <RightMenu/>

    <QrDialog/>

    <div class="col-6 mx-auto" v-for="notice in $store.state.notification">
        <v-alert dismissible :type="notice.type" :color="notice.color">{{notice.detail}}</v-alert>
    </div>

    <!-- QR Comment -->
    <v-dialog v-model="$store.state.qr.read" :overlay-color="$store.state.settings.style.primary" max-width="1000px">
      <qrcode-stream @decode="onDecode"></qrcode-stream>
    </v-dialog>
    <v-container v-if="read_data">
      QRコード テスト | 読み取った人 : {{ $store.state.user.username }} 様<br />
      読み取った情報 | {{ read_data }}
    </v-container>
  

    <!-- Responsive Footer  -->
    <div class="hidden-sm-and-up">
      <v-footer fixed :color="$store.state.settings.style.primary" dark>
        <v-row no-gutters align="center" justify="space-between">
          <v-btn icon @click="$store.state.drawer.left.state = !$store.state.drawer.left.state"><v-icon>mdi-menu</v-icon></v-btn>
          <v-spacer></v-spacer>
          <!-- QR Comment -->
          <v-toolbar-title center>
            <v-row no-gutters>
              <v-col justify="center" align="center">
                <v-btn icon x-large @click="$store.state.qr.read = !$store.state.qr.read"><v-icon>mdi-qrcode-scan</v-icon></v-btn>
                <br />
                <h6>読取</h6>
              </v-col>
              <v-col justify="center" align="center">
                <v-btn icon x-large @click="$store.state.qr.write = !$store.state.qr.write"><v-icon>mdi-qrcode</v-icon></v-btn>
                <br />
                <h6>表示</h6>
              </v-col>
            </v-row>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.drawer.right.state = !$store.state.drawer.right.state"
            ><v-icon>mdi-microsoft-xbox-controller-menu</v-icon></v-btn>
        </v-row>
      </v-footer>
    </div>


  </div>
</template>

<script>
import Vue from "vue";
// QR Comment
import VueQrcode from "@/components/common/qrcode/Qrcode.vue";
import QrDialog from "@/components/common/qrcode/QrDialog.vue"

// import TopLeft from "@/components/common/drawer/TopLeft.vue";
// import TopRight from "@/components/common/drawer/TopRight.vue";
// import BottomLeft from "@/components/common/drawer/BottomLeft.vue";
// import BottomRight from "@/components/common/drawer/BottomRight.vue";

import LeftMenu from "@/components/common/drawer/LeftMenu.vue";
import RightMenu from "@/components/common/drawer/RightMenu.vue";

export default Vue.extend({
  name: "Navigation",
  props: ["title"],
  // components: { TopLeft,TopRight,BottomLeft,BottomRight },
  components: { LeftMenu,RightMenu, VueQrcode, QrDialog },
  data() {
    return {
      datas: [],
      read_data:null,
    };
  },
  mounted() {
    // QR Comment
    this.$store.state.qr.value = "利用者 : " + this.$store.state.user.username + " 様 | 使用App(画面) : " + this.$route.name
    this.$vuetify.theme.dark = this.$store.state.settings.style.dark
    switch (this.$store.state.user.class){
      case "general":
        // console.log("nav general")
        this.$store.state.drawer.left.menu = this.$store.state.client_list
        this.$store.state.drawer.right.menu = this.$store.state.general_list
        break;
      case "client":
        // console.log("nav client")
        this.$store.state.drawer.left.menu = this.$store.state.client_list
        this.$store.state.drawer.right.menu = this.$store.state.general_list
        break;
      case "admin":
        // console.log("nav admin")
        this.$store.state.drawer.left.menu = this.$store.state.client_list
        this.$store.state.drawer.right.menu = this.$store.state.general_list
        this.$store.state.drawer.absolute = [
          {title:"DeviceManager",icon:"mdi-store-clock",path:"/devicemanager",color:"green lighten-2"},
          {title:"ClientManager",icon:"mdi-account-key",path:"/clientmanager", color:"red lighten-1"},
          {title:"Settings",icon:"mdi-cog-outline",path:"/settings", color:"secondary"},
          {title:"Update",icon:"mdi-cog-sync-outline", color:"notice"},  
          {title:"MasterUpdate",icon:"mdi-cog-transfer-outline", color:"black"},
        ]
        break;
      default:
        this.$store.state.drawer.left.menu = this.$store.state.client_list
        this.$store.state.drawer.right.menu = this.$store.state.general_list
        break;
    }

  },
  methods: {
    // QR Comment
    onDecode(decodedString) {
      this.read_data = decodedString;
      this.$store.state.qr.read = false;
    },
    openCamera: function () {
      // console.log("openCamera");
      this.$store.state.qr.read = true;
    },
    updateApp: function () {
      let sw = window.navigator.serviceWorker.register(
        this.$store.state.api_url + "/service-worker.js"
      );
      // console.log(sw);
      confirm("アプリケーション更新チェック");
      window.navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          alert("サービスワーカー解除");
          registration.unregister();
        }
      });
      alert("アプリケーションを再起動してください");
      // window.location.reload(true);
    },
  },
});
</script>
